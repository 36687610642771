import { gql } from 'graphql-request';
import { request } from '../graphql';

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export type LoginData = {
  login?: string
}

export type LoginVars = {
  email: string;
  password: string;
}

export const mutateLogin = (vars: LoginVars) => request<LoginData>(LOGIN_MUTATION, vars);
