import { gql } from 'graphql-request';
import { request } from '../graphql';

export const ASK_RECOVER = gql`
  mutation askRecover( $email: String!, $path: String ) {
    askRecover(email: $email, path: $path)
  }
`;

export type AskRecoverVars = {
  email: string;
  path: string;
}

export const mutateAskRecover = (vars: AskRecoverVars) =>
  request<boolean>(ASK_RECOVER, vars);
