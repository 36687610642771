import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_MY_DASHBOARDS = gql`
  query profile {
    getMyProfile {
      company {
        _id
        homeDashboard
        realTimeSequenceOfDashboard {
          dashboardTabs {
            dashboardId
            title
            order
          }
        }
        dashboards {
          _id
          order
          title
          reckonings {
            _id
            endDate
          }
          sequenceOfDashboard {
            title
            tagColor
            tagName
            dashboardTabs {
              dashboardId
              title
              order
            }
          }
        }
        realTimeDashboardSummary
        realTimeDashboardUsage
        realTimeDashboardFeature
        realTimeDashboardSegment
      }
    }
  }
`;

export type GetMyDashboardsData = {
  getMyProfile: {
    company: {
      _id: string;
      homeDashboard:string;
      realTimeSequenceOfDashboard: {
        dashboardTabs: {
          dashboardId: string;
          title: string;
          order: number;
        }[]
      }
      dashboards: {
        _id: string;
        order: number;
        title:string;
        reckonings: {
          _id: string;
          endDate: string,
        }[]
        sequenceOfDashboard: {
          tagColor: string;
          tagName: string;
          title: string;
          dashboardTabs: {
            dashboardId: string;
            title: string;
            order: number;
          }[]
        }
      }[]
    }
  }
}

export const getMyDashboards = () => request<GetMyDashboardsData>(GET_MY_DASHBOARDS, {});
