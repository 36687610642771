import { Accessor, createResource } from 'solid-js';
import { getLuzmoSso } from '../requests/evoluno';

export const createLuzmoSso = (userValid: Accessor<boolean>) => {
  return createResource(
    userValid,
    async () => {
      const data = await getLuzmoSso(true);
      return data.luzmoSSO;
    });
};
