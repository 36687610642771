import Navigation from '../../components/Navigation';
import NavigationTop from '../../components/NavigationTop';
import { ErrorBoundary, ParentComponent } from 'solid-js';

export const Main: ParentComponent = props => {
  return (
    <div>
      <div class="flex min-h-screen">
        <Navigation />
        <div class="bg-surface-primary flex max-h-screen w-full flex-col overflow-y-auto px-16">
          <NavigationTop/>
          <ErrorBoundary fallback={<p>An error occurred. Please refresh the page.</p>}>
            {props.children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Main;
