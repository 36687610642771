import { GraphQLClient } from 'graphql-request';
import { getApiUrl } from '../../config';

const language = 'en';
const endpoint = `${getApiUrl()}/${language}/graphql`;
const gqlClient = new GraphQLClient(endpoint);

export const request = async <T>(
  query: string,
  variables?: any,
  token = localStorage.getItem('token'),
): Promise<T> => {
  gqlClient.setHeader('Authorization', token ? `Bearer ${token}` : '');
  return gqlClient.request<T>(query, variables);
};
