import Background from '../../components/Background';
import { ParentComponent } from 'solid-js';

export const Login: ParentComponent  = props => {

  return (
    <div class="flex min-h-screen bg-white max-md:flex-col">
      <div class="flex w-1/2 flex-col max-md:w-full">
        <Background/>
      </div>
      <div class="flex w-1/2 flex-col max-md:w-full">
        {props.children}
      </div>
    </div>
  );
};

export default Login;
