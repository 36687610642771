import * as Sentry from '@sentry/browser';
import { Resource, createResource, createSignal } from 'solid-js';
import { SigninResource, verifyToken } from './createSignin';
import { ampli } from '../ampli';
import { decodeToken } from '../utils/jwt-helper';
import { mutateRecover } from '../requests/evoluno/mutations/recover';

export type RecoverState =  {
  submit: (password: string, recoveryToken: string) => void,
  reset: () => void,
  data: Resource<SigninResource>,
}

export type RecoverSubmission = false | {
  password: string;
  recoveryToken: string;
}

export const createRecover = (signout: () => void): RecoverState => {
  const [submit, setSubmit] = createSignal<RecoverSubmission>(false);
  const [data] = createResource(
    submit,
    async submission => {
      const recoveryData = decodeToken(submission.recoveryToken);
      if (!recoveryData) {
        throw new Error('Invalid recovery token');
      }
      const data = await mutateRecover({
        email: recoveryData.email as string,
        code: recoveryData.code as string,
        password: submission.password,
      }, submission.recoveryToken);
      const { recover: token } = data || {};
      const decoded = verifyToken(token || '', signout);
      localStorage.setItem('token', token || '');
      Sentry.setUser({_id: decoded._id, email: decoded.email as string});
      ampli.identify(decoded.email as string || decoded._id as string, {
        'Company ID': decoded.company as string,
      });
      return { token, decoded } as SigninResource;
    }
  );
  return {
    submit: (password, token) => {
      setSubmit({password, recoveryToken: token});
    },
    reset: () => {
      setSubmit(false);
    },
    data,
  };
};
