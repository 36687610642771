import { createMemo } from 'solid-js';
import { getLabels } from '../utils/getLabels';
import { useState } from './context';

export const useLabels = (path: string, initAtApp = true) => {
  const text = createMemo(() => {
    const {labels} = useState();
    return getLabels(path, initAtApp, labels);
  });
  return text;
};