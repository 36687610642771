import { Accessor, createResource } from 'solid-js';
import { getLuzmoSso } from '../requests/evoluno';

export const createLuzmoSsoSurvey = (dashboardAccess: Accessor<string | undefined>) => {
  return createResource(
    dashboardAccess,
    async () => {
      const data = await getLuzmoSso(false, dashboardAccess());
      return data.luzmoSSO;
    }
  );
};
