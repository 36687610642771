import LogoEvoluno from '../LogoEvoluno';
import NavigationMenu from './NavigationMenu';
import { Component } from 'solid-js';
import { Paths } from '../../config';
import { useNavigate } from '@solidjs/router';

const Navigation: Component = () => {
  const navigate = useNavigate();
  return (
    <div
      class="
        bg-surface-primary-dark
        flex min-w-72 flex-col px-4 py-12
        max-xl:min-w-48
        max-md:min-w-max
      "
    >
      <div onClick={() => navigate(Paths.home)} class="cursor-pointer self-center">
        <LogoEvoluno withText={false} class="size-24 max-md:size-8" />
      </div>
      <NavigationMenu />
    </div>
  );
};

export default Navigation;
