import { Component, JSX, Show, mergeProps, splitProps } from 'solid-js';

export type InputProps = {
  id: string;
  ref?: HTMLInputElement;
  /**
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: string;
  placeholder?: string;
  value: string;
  type?: string;
  onChange?: (value: string) => void,
  icon?: JSX.Element;
  label?: string;
  class?: string;
  required?: boolean;
  disabled?: boolean;
  pattern?: string;
  oninvalid?: JSX.InputHTMLAttributes<HTMLInputElement>['oninvalid'];
}

export const Input: Component<InputProps> = allProps => {
  const [props, splitInputProps] = splitProps(allProps, ['onChange', 'icon', 'label']);
  const inputProps = mergeProps({
    type: 'text',
    class: '',
  }, splitInputProps);

  return (
    <div>
      <Show when={props.label} keyed>
        {label => <label for={inputProps.id} class="mt-9 min-w-full text-sm font-bold"> {label} </label> }
      </Show>
      <div
        class="
          border-border-primary focus-within:border-surface-inverse-primary
          has-[:invalid]:border-state-danger-primary
          has-[:placeholder-shown]:border-border-primary
          has-[:placeholder-shown:focus-within]:border-surface-inverse-primary
          mt-1.5 flex w-96 max-w-full gap-3 whitespace-nowrap rounded-lg
          border border-solid bg-white px-5 py-3
          has-[:focus-within]:shadow-[0px_0px_4px_rgba(103,91,223)]
          max-lg:w-72 max-md:w-60">
        <Show when={props.icon} keyed>
          {Icon => Icon}
        </Show>
        <input
          {...inputProps}
          class={`${inputProps.class} w-full focus:outline-none`}
          onChange={e => props.onChange?.(e.currentTarget.value)}
        />
      </div>
    </div>
  );
};