import * as Sentry from '@sentry/browser';
import { Resource, createResource, createSignal } from 'solid-js';
import { SigninResource, verifyToken } from './createSignin';
import { ampli } from '../ampli';
import { decodeToken } from '../utils/jwt-helper';
import { mutateRegisterRh } from '../requests/evoluno';

export type RegisterSubmission = false | {
  firstName: string;
  lastName: string;
  password: string;
  registerToken: string;
}

export type RegisterState = {
  submit: (firstName: string, lastName: string, password: string, registerToken: string) => void
  reset: () => void;
  data: Resource<SigninResource>
}

export const createRegister = (signout: () => void): RegisterState => {
  const [submit, setSubmit] = createSignal<RegisterSubmission>(false);
  const [data] = createResource(
    submit,
    async submission => {
      const registerData = decodeToken(submission.registerToken);
      if (!registerData) {
        throw new Error('Invalid register token');
      }
      const data = await mutateRegisterRh({
        firstName: submission.firstName,
        lastName: submission.lastName,
        password: submission.password,
      }, submission.registerToken);
      const { registerRh: token } = data || {};
      const decoded = verifyToken(token || '', signout);
      localStorage.setItem('token', token || '');
      Sentry.setUser({_id: decoded._id, email: decoded.email as string});
      ampli.identify(decoded.email as string || decoded._id as string, {
        'Company ID': decoded.company as string,
      });
      return { token, decoded } as SigninResource;
    }
  );
  return {
    submit: (firstName, lastName, password, registerToken) => {
      setSubmit({firstName, lastName, password, registerToken});
    },
    reset: () => {
      setSubmit(false);
    },
    data,
  };
};
