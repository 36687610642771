import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_LANGUAGES = gql`
  query getLanguages {
    languages(criteria: { filters: { availableInApp: { operation: EQ, value: true } }}) {
      items {
        _id
        key
        name
      }
    }
  }
`;

export type GetLanguagesData = {
  languages?: {
    items?: {
      _id?: string;
      key?: string;
      name?: string;
    }[];
  };
};

export const getLanguages = () => request<GetLanguagesData>(GET_LANGUAGES);
