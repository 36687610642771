import { gql } from 'graphql-request';
import { request } from '../graphql';

export const SILENT_LOGIN = gql`
  mutation silentLogin {
    silentLogin
  }
`;

export type SilentLoginData = {
  silentLogin?: string
}

export const mutateSilentLogin = () => request<SilentLoginData>(SILENT_LOGIN);
