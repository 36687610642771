import { Component, lazy } from 'solid-js';

const BackgroundC = lazy(() => import('../Background/BackgroundC'));

export const UnderConstruction: Component = () => {
  return (
    <div class="flex min-h-screen bg-white max-md:flex-col">
      <div class="flex w-1/2 flex-col max-md:w-full">
        <BackgroundC />
      </div>
      <div class="flex w-1/2 flex-col gap-8 p-12 max-md:w-full">
        <h1>We'll be right back</h1>
        <p>Apologies for any inconvenience.</p>
      </div>
    </div>
  );
};