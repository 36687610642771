import { Accessor, createResource } from 'solid-js';
import { getLocale } from '../requests/evoluno';

export type LabelSource = false | string;
export const createLabels = (language: Accessor<LabelSource>) => {
  return createResource(
    language,
    async lang => {
      const labels = await getLocale(lang);
      return labels.locale.data;
    }
  );
};
