import Logo from '../../assets/logo-evoluno.svg?component-solid';
import LogoNoText from '../../assets/logo-evoluno-notext.svg?component-solid';
import { Component, Show, mergeProps } from 'solid-js';

export type LogoEvolunoProps = {
  withText?: boolean;
  class?: string;
}

export const LogoEvoluno: Component<LogoEvolunoProps> = props => {
  const merged = mergeProps({
    withText: true,
    class: 'size-32'
  }, props);
  return (
    <Show when={merged.withText} fallback={<LogoNoText class={merged.class} />}>
      <Logo class={merged.class} />
    </Show>
  );
};

export default LogoEvoluno;
