export default (props = {}) => <svg xmlns="http://www.w3.org/2000/svg" width="68" height="39" viewBox="0 0 68 39" fill="none"{...props}>
  <g filter="url(#filter0_i_4380_6774)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.7469 19.7553C39.7464 19.7726 39.7459 19.7899 39.7453 19.8072L44.1472 17.4861C45.2831 16.8871 46.6907 17.0749 47.6162 17.949L53.2041 23.2267L30.2532 35.58C29.7843 35.8577 29.3026 36.1171 28.8091 36.3573L28.469 36.5404L28.4783 36.5146C25.8756 37.723 22.9584 38.4 19.8776 38.4C8.89948 38.4 0 29.8039 0 19.2C0 8.59613 8.89948 0 19.8776 0C24.2908 0 28.3682 1.38923 31.6665 3.73965L20.5721 9.70793C20.4085 9.70065 20.2439 9.69697 20.0783 9.69697C14.423 9.69697 9.83838 13.995 9.83838 19.297C9.83838 24.5989 14.423 28.897 20.0783 28.897C21.3711 28.897 22.6079 28.6724 23.7468 28.2626L23.7573 28.2948C23.7573 28.2948 23.8865 28.2331 24.123 28.119C24.5731 27.9374 25.0065 27.7264 25.4205 27.4886C28.754 25.8582 36.2334 22.1235 39.7469 19.7553Z" fill="url(#paint0_linear_4380_6774)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2158 18.6447C28.2163 18.6274 28.2169 18.6101 28.2174 18.5928L23.8156 20.9139C22.6796 21.5129 21.272 21.3251 20.3466 20.451L14.7587 15.1733L37.7096 2.81998C38.1784 2.54229 38.6602 2.28285 39.1537 2.0427L39.4938 1.85963L39.4845 1.88541C42.0872 0.677012 45.0043 0 48.0852 0C59.0633 0 67.9628 8.59613 67.9628 19.2C67.9628 29.8039 59.0633 38.4 48.0852 38.4C43.6719 38.4 39.5946 37.0108 36.2963 34.6604L47.3907 28.6921C47.5543 28.6994 47.7189 28.703 47.8844 28.703C53.5398 28.703 58.1244 24.405 58.1244 19.103C58.1244 13.8011 53.5398 9.50303 47.8844 9.50303C46.5917 9.50303 45.3549 9.72761 44.216 10.1374L44.2055 10.1052C44.2055 10.1052 44.0763 10.1669 43.8398 10.281C43.3897 10.4626 42.9562 10.6736 42.5422 10.9114C39.2087 12.5418 31.7294 16.2765 28.2158 18.6447Z" fill="url(#paint1_linear_4380_6774)"/>
  </g>
  <defs>
    <filter id="filter0_i_4380_6774" x="0" y="0" width="67.9629" height="38.4004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="0.529056"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.103447 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4380_6774"/>
    </filter>
    <linearGradient id="paint0_linear_4380_6774" x1="64.894" y1="41.9821" x2="64.894" y2="-1.84746" gradientUnits="userSpaceOnUse">
      <stop stop-color="#675BDF"/>
      <stop offset="1" stop-color="#82CBE1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4380_6774" x1="75.1363" y1="43.3116" x2="75.1363" y2="-5.16315e-06" gradientUnits="userSpaceOnUse">
      <stop stop-color="#4F9EB5"/>
      <stop offset="1" stop-color="#A0DBEC"/>
    </linearGradient>
  </defs>
</svg>
