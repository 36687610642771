import { Accessor, Resource, Setter, createMemo, createSignal } from 'solid-js';
import { SigninResource } from './createSignin';

export type SessionState = Accessor<{
  loading: boolean;
  error: boolean;
  data?: Record<string, string>
  setRedirect: Setter<string|undefined>;
  redirect: Accessor<string|undefined>;
}>;

export const createSession = (
  signinSources: Resource<SigninResource>[],
): SessionState => {
  const [redirect, setRedirect] = createSignal<string | undefined>();
  const session = createMemo(() => {
    const loading = signinSources.some(signin => signin.loading);
    const error = signinSources.some(signin => signin.error);
    const session = { loading, error, setRedirect, redirect };
    for (const source of signinSources) {
      if (source.state === 'ready') {
        return {
          ...session,
          data: source()?.decoded || {},
        };
      }
    }
    return session;
  });
  return session;
};
