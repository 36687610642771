import { Component } from 'solid-js';

export type ButtonProps = {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  text?: string;
  disabled?: boolean
  leftIcon?: Component
  ref?: HTMLButtonElement;
}

export const Button: Component<ButtonProps> = props => {
  return (
    <button
      ref={props.ref}
      id={props.id}
      class="
        bg-brand-500 active:bg-brand-900 hover:shadow-brand-500/40 disabled:bg-brand-300
        mt-9 flex max-w-full flex-row items-center justify-center gap-3 whitespace-nowrap rounded-3xl px-6 py-3 text-center text-white
        hover:shadow-lg
        disabled:cursor-not-allowed disabled:opacity-70 disabled:shadow-none
        lg:px-8"
      disabled={props.disabled}
      type={props.type}
      onClick={() => props.onClick?.()}
    >
      {props.leftIcon && <props.leftIcon />}
      {props.text}
    </button>
  );
};