import { getApiUrl } from '../../config';

export const getLocaleApiUrl = (language: string) =>
  `${getApiUrl()}/${language}/locale`;


type LocaleData = {
  locale: {
    lang: string;
    data: unknown
  }
}

export const getLocale = async(language: string) => {
  const response = await fetch(getLocaleApiUrl(language));
  return await response.json() as LocaleData;
};
