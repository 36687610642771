export const decodeToken = (token: string): undefined | Record<string, string | number | boolean> => {
  if (!token) {
    return undefined;
  }
  const parts = token.split('.');

  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }

  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }

  return JSON.parse(decoded);
};

export const urlBase64Decode = (str: string) => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
  case 0: {
    break;
  }
  case 2: {
    output += '==';
    break;
  }
  case 3: {
    output += '=';
    break;
  }
  default: {
    // TODO
    throw new Error('Illegal base64url string!');
  }
  }
  return b64DecodeUnicode(output);
};

export const b64DecodeUnicode = (str: any) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(b64decode(str), (c: any) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export const b64decode = (str: string) => {
  const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';

  str = String(str).replace(/=+$/, '');

  if (str.length % 4 === 1) {
    throw new Error(
      '\'atob\' failed: The string to be decoded is not correctly encoded.'
    );
  }

  for (
  // initialize result and counters
    let bc = 0, bs: any, buffer: any, idx = 0;
  // tslint:disable-next-line:no-conditional-assignment
    (buffer = str.charAt(idx++));
  // tslint:disable-next-line:no-bitwise
    ~buffer &&
      // tslint:disable-next-line:no-conditional-assignment
      ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? // tslint:disable-next-line:no-bitwise
      (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    // try to find character in table (0-63, not found => -1)
    buffer = chars.indexOf(buffer);
  }
  return output;
};
