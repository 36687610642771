import * as Sentry from '@sentry/browser';
import { Accessor, Resource, createResource, createSignal } from 'solid-js';
import { Signout } from './createSignout';
import { ampli } from '../ampli';
import { decodeToken } from '../utils/jwt-helper';
import { mutateLogin } from '../requests/evoluno';

export type SigninState = {
  submit: (email: string, password: string) => void,
  submitted: Accessor<SigninSource>,
  reset: () => void,
  data: Resource<SigninResource>
}
export type SigninSource = false | {email: string, password: string}
export type SigninResource = {
  token?: string
  decoded: Record<string, string>
}

export const verifyToken = (token: string, signout: Signout): Record<string, string | boolean | number> => {
  const decodedData = decodeToken(token);
  if (!(['RH', 'Admin'].includes((decodedData?.role as string) || ''))) {
    signout({reload: false, roleError: true});
  }
  return decodedData || {};
};

/**
 * signin is a resource that triggers a signin attempt when submit is set
 */
export const createSignin = (signout: Signout): SigninState => {
  // submit is a signal that will be set to the email and password when the user submits the form
  const [submit, setSubmit] = createSignal<SigninSource>(false);
  const [signin] = createResource(
    submit,
    async submission => {
      const data = await mutateLogin(submission);
      const { login: token } = data;
      const decoded = verifyToken(token || '', signout);
      localStorage.setItem('token', token || '');
      Sentry.setUser({_id: decoded._id, email: decoded.email as string});
      ampli.identify(decoded.email as string || decoded._id as string, {
        'Company ID': decoded.company as string,
      });
      return { token, decoded } as SigninResource;
    },
  );
  return {
    submit: (email: string, password: string) => {
      setSubmit({email, password});
    },
    submitted: submit,
    reset: () => {
      setSubmit(false);
    },
    data: signin,
  };
};

export default createSignin;

