import * as Sentry from '@sentry/browser';
import { Accessor, createResource } from 'solid-js';
import { SigninResource, verifyToken } from './createSignin';
import { ampli } from '../ampli';
import { mutateSilentLogin } from '../requests/evoluno';

export const createTokenRefresh = (userInvalid: Accessor<boolean>, signout: () => void) => {
  return createResource(
    userInvalid,
    async () => {
      const data = await mutateSilentLogin();
      const { silentLogin: token } = data;
      const decoded = verifyToken(token || '', signout);
      localStorage.setItem('token', token || '');
      Sentry.setUser({_id: decoded._id, email: decoded.email as string});
      ampli.identify(decoded.email as string || decoded._id as string, {
        'Company ID': decoded.company as string,
      });
      return { token, decoded } as SigninResource;
    }
  );
};
