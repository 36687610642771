import { USER_FIELDS } from './updateUser';
import { gql } from 'graphql-request';
import { request } from '../graphql';

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      ...fields
    }
  }
  ${USER_FIELDS}
`;

export type ChangePasswordVars = {
  oldPassword: string;
  newPassword: string;
}

export const mutateChangePassword = (vars: ChangePasswordVars) =>
  request<boolean>(CHANGE_PASSWORD, vars);
