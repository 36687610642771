import LogoEvoluno from '../LogoEvoluno';
import { type Component, Match, Switch, lazy } from 'solid-js';
import { Paths } from '../../config';
import { useLocation } from '@solidjs/router';

const BackgroundB = lazy(() => import('./BackgroundB'));
const BackgroundC = lazy(() => import('./BackgroundC'));

const Background: Component = () => {

  const location = useLocation();

  return (
    <Switch fallback={
      <header class="bg-surface-primary-dark flex size-full items-center justify-center">
        <LogoEvoluno withText={true} class="size-32" />
      </header>
    }>
      <Match when={location.pathname === Paths.passwordReset}>
        <BackgroundB />
      </Match>
      <Match when={location.pathname.includes(Paths.passwordNew)}>
        <BackgroundC />
      </Match>
    </Switch>
  );
};

export default Background;
