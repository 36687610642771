import * as Sentry from '@sentry/browser';
import { Resource } from 'solid-js';
import { ampli } from '../ampli';
import { getLabels } from '../utils/getLabels';

export const createSignout = (labels: Resource<unknown>) => ({reload = true, roleError = false} = {}) => {
  if (ampli.isLoaded) {
    ampli.client.reset();
  }
  Sentry.setUser(null);
  localStorage.clear();
  if (reload) {
    location.reload();
  }
  if (roleError) {
    throw new Error(getLabels('LOGIN', true, labels)?.ROLE_ERROR || 'You do not have the necessary permissions to access this page.');
  }
};
export type Signout = ReturnType<typeof createSignout>;
