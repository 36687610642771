import { gql } from 'graphql-request';
import { request } from '../graphql';

export const USER_FIELDS = gql`
  fragment fields on User {
    _id
    email
    name
    firstName
    lastName
    active
    role {
      _id
      name
      class
    }
    profileImage
    preferredLanguage
  }
`;

export const UPDATE = gql`
  mutation updateUser(
    $input: UserUpdateInput!
  ) {
    updateUser(
      input: $input
    ) {
      ...fields
    }
  }
  ${USER_FIELDS}
`;

export type UpdateUserVars = {
  input: {
    _id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    preferredLanguage?: string;
  };
}

export type UpdateUserData = {
  updateUser: {
    _id: string;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    active: boolean;
    role: {
      _id: string;
      name: string;
      class: string;
    };
    profileImage: string | null;
    preferredLanguage: string;
  }
}

export const mutateUpdateUser = (vars: UpdateUserVars) =>
  request<UpdateUserData>(UPDATE, vars);
