import { Component, Show } from 'solid-js';
import { FiChevronLeft } from 'solid-icons/fi';
import { useLabels } from '../../signals/useLabels';
import { useLocation, useNavigate, useParams } from '@solidjs/router';

export const BackButton: Component = () => {
  const labels = useLabels('NAVIGATION');
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      class="flex cursor-pointer items-center gap-2"
      onClick={() => navigate(location.pathname.replace(`/${params.id}`, ''))}
    >
      <Show when={params.id}>
        <FiChevronLeft size={24} />
        {labels()?.BACK || 'Retour'}
      </Show>
    </div>
  );
};
