import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_LUZMO_SSO = gql`
  query luzmoSSO($realTime: Boolean!, $accessOfDashboard: String) {
    luzmoSSO(realTime: $realTime, accessOfDashboard: $accessOfDashboard)  {
      id
      token
    }
  }
`;

export type GetLuzmoSsoData = {
  luzmoSSO: {
    id: string;
    token: string;
  }
}

export const getLuzmoSso = (realTime: boolean, accessOfDashboard?: string) =>
  request<GetLuzmoSsoData>(GET_LUZMO_SSO, { realTime, accessOfDashboard });
