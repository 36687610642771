import { Accessor, createMemo, createResource, createSignal } from 'solid-js';
import { GetMyDashboardsData, getMyDashboards } from '../requests/evoluno';

export type Dashboards = {
  realtime: {
    loading: boolean;
    error: unknown;
    homeDashboard: Accessor<string | undefined>;
    list: Accessor<undefined | GetMyDashboardsData['getMyProfile']['company']['realTimeSequenceOfDashboard']>,
  }
  surveys: {
    loading: boolean;
    error: unknown;
    list: Accessor<undefined | GetMyDashboardsData['getMyProfile']['company']['dashboards']>,
    selection: Accessor<GetMyDashboardsData['getMyProfile']['company']['dashboards'][0] | undefined>,
    selectionId: Accessor<string | undefined>,
    select: (id: string) => void,
  }
}

export const createDashboards = (userValid: Accessor<boolean>): Dashboards => {
  const [profile] = createResource(
    userValid,
    async () => {
      const data = await getMyDashboards();
      return data.getMyProfile;
    });
  const realtimelist = createMemo(
    () => profile.loading || profile.error
      ? undefined
      : profile()?.company.realTimeSequenceOfDashboard
  );
  const surveys = createMemo(
    () => profile.loading || profile.error
      ? undefined
      : profile()?.company.dashboards
  );
  const homeDashboard = createMemo(
    () => profile.loading || profile.error
      ? undefined
      : profile()?.company.homeDashboard
  );
  const [selectedSurveyId, setSelectedSurveyId] = createSignal<string | undefined>();
  const selectedSurvey = createMemo(
    () => profile.loading || profile.error
      ? undefined
      : profile()?.company.dashboards.find(survey => survey._id === selectedSurveyId())
  );
  return {
    realtime: {
      list: realtimelist,
      homeDashboard,
      get error() {
        return profile.error;
      },
      get loading() {
        return profile.loading;
      }
    },
    surveys: {
      get error() {
        return profile.error;
      },
      get loading() {
        return profile.loading;
      },
      list: surveys,
      selection: selectedSurvey,
      selectionId: selectedSurveyId,
      select: setSelectedSurveyId,
    }
  };
};
