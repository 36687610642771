import { BackButton } from './BackButton';
import { Component } from 'solid-js';
import { MenuProfile } from './MenuProfile';

export const NavigationTop: Component = () => {
  return (
    <div class="flex max-w-full items-center justify-between gap-5 py-7">
      <BackButton/>
      <MenuProfile/>
    </div>
  );
};
