import { gql } from 'graphql-request';
import { request } from '../graphql';

export const RECOVER = gql`
  mutation recover(
    $password: String!
    $code: String!
    $email: String!
  ) {
    recover(password: $password, code: $code, email: $email)
  }
`;

export type RecoverData = {
  recover?: string;
}

export type RecoverVars = {
  password: string;
  code: string;
  email: string;
}

export const mutateRecover = (vars: RecoverVars, token: string) =>
  request<RecoverData>(RECOVER, vars, token);
