import { A } from '@solidjs/router';
import { Component, JSX, Show } from 'solid-js';

export type NavigationMenuItemProps = {
  onClick?: () => void;
  text: string;
  disabled?: boolean
  icon: JSX.Element,
  active?: boolean
  to: string;
}

const classes = `
group
text-content-inverse-primary
hover:text-content-accent
border-surface-inverse-primary
aria-disabled:text-content-secondary
flex flex-row gap-3
rounded-md py-2.5 pl-4
pr-16
text-left
max-md:w-10
max-md:justify-center
max-md:px-0
`;

export const NavigationMenuItem: Component<NavigationMenuItemProps> = props => {

  return (
    <Show
      when={!props.disabled}
      fallback={
        <div class={classes + ' cursor-not-allowed'} aria-disabled={true}>
          {props.icon}
          <span class="max-md:hidden">
            {props.text}
          </span>
        </div>
      }
    >
      <A
        class={classes}
        activeClass='bg-surface-inverse-primary hover:text-content-inverse-primary '
        href={props.to}
        aria-disabled={props.disabled}
      >
        {props.icon}
        <span class="max-md:hidden">
          {props.text}
        </span>
      </A>
    </Show>
  );
};

export default NavigationMenuItem;
