import { gql } from 'graphql-request';
import { request } from '../graphql';

export const REGISTER_RH = gql`
mutation registerRh(
  $input: UserRegisterRhInput!
) {
  registerRh(input: $input)
}
`;

export type RegisterVarsRh = {
  firstName: string;
  lastName: string;
  password: string;
}

export type RegisterDataRh = {
  registerRh: string;
}

export const mutateRegisterRh = (vars: RegisterVarsRh, registerToken: string) =>
  request<RegisterDataRh>(REGISTER_RH, {input: vars}, registerToken);

