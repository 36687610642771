import { Resource } from 'solid-js';

const NOT_FOUND_STRING = 'Not found';

export const getLabels = (path: string, initAtApp = true, labelsResource: Resource<unknown>): any => {
  if (labelsResource.loading || labelsResource.error) return NOT_FOUND_STRING;
  const data = labelsResource() as any;
  const labels = initAtApp
    ? data?.PLATFORM_RH?.APP
    : data;

  if (!labels) return '';
  const keys = path.split('.');
  const recursiveLabelSearch: (labels: any, index: number) => any = (labels: any, index: number) => {
    if (!labels) return NOT_FOUND_STRING;
    const key = keys[index];
    if (!key) return labels;
    if (keys.length - 1 > index && (!(key in labels) || !key)) return NOT_FOUND_STRING;
    if (keys.length - 1 === index) return labels[key];
    else return recursiveLabelSearch(labels[key], index + 1);
  };
  const beforeReturn = recursiveLabelSearch(labels, 0);
  return beforeReturn;
};
