
const API_DEMO  = 'https://demo.evoluno.com';
const API_LOCAL = import.meta.env.VITE_API_URL;

export const getApiUrl = () => {
  if (API_LOCAL) {
    return API_LOCAL;
  } else {
    return API_DEMO;
  }
};

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE) || 1.0;
export const SENTRY_REPLAY_SESSION_RATE = Number(import.meta.env.VITE_SENTRY_REPLAY_SESSION_RATE) || 0.1;
export const SENTRY_REPLAY_ERRORS_RATE = Number(import.meta.env.VITE_SENTRY_REPLAY_ERRORS_RATE) || 1.0;
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
