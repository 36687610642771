import Button from '../Button';
import Input from '../Input';
import { type Component, Show, createEffect, createSignal } from 'solid-js';
import { FiInfo, FiLock, FiMail } from 'solid-icons/fi';
import { Paths } from '../../config';
import { useLabels } from '../../signals/useLabels';
import { useState } from '../../signals/context';

let refInputEmail: HTMLInputElement | undefined;
let refInputPassword: HTMLInputElement | undefined;

const LoginForm: Component = () => {
  const [email, setEmail] = createSignal('');
  const [password, setPassword] = createSignal('');

  const {signin} = useState();
  const labels = useLabels('LOGIN');

  const [signinError, setSigninError] = createSignal<string | undefined>();

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    signin.submit(email(), password());
  };

  const handleEmailChange = (e: string) => {
    setEmail(e);
    refInputEmail?.setCustomValidity('');
    refInputPassword?.setCustomValidity('');
    setSigninError();
  };

  const handlePasswordChange = (p: string) => {
    setPassword(p);
    refInputEmail?.setCustomValidity('');
    refInputPassword?.setCustomValidity('');
    setSigninError();
  };

  createEffect(() => {
    // reset the submit flag after signin attempt
    if (!signin.data.loading && signin.submitted()) {
      signin.reset();
    }
    if (signin.data.error) {
      const signinError = setSigninError(signin.data.error?.response?.errors?.[0]?.message ||
        signin.data.error.message ||
        labels()?.ERROR_BASE);
      refInputEmail?.setCustomValidity(signinError);
      refInputPassword?.setCustomValidity(signinError);
      // refInputEmail?.reportValidity();
      console.error(signinError);
    }
  });

  return (
    <main class="flex h-full flex-col place-content-center justify-center max-md:max-w-full">
      <h1 class="grow pt-32 text-center text-4xl">
        {labels()?.SIGN_IN_TITLE}
      </h1>
      <form
        onSubmit={handleSubmit}
        class="flex flex-col items-center justify-center gap-4"
      >
        <h2>
          {labels()?.WELCOME_BACK || 'Bon retour sur Evoluno !'}
        </h2>
        <p>
          {labels()?.ENTER_YOUR_DETAILS  || 'Veuillez renseigner vos identifiants de connexion.'}
        </p>
        <Show when={signinError()} keyed>
          {error => (
            <div class='bg-state-danger-secondary w-96 rounded p-4 max-lg:w-72 max-md:w-60'>
              <span class='text-state-danger-primary flex flex-row items-center font-bold'>
                <FiInfo class='stroke-state-danger-primary stroke-2 pr-1' size={16} />
                {labels()?.ERROR_BASE}
              </span>
              <p class='text-state-danger-primary'>{error}</p>
            </div>
          )}
        </Show>
        <Input
          id="email"
          ref={refInputEmail}
          autocomplete='email'
          class="flex-auto"
          type="email"
          placeholder={labels()?.EMAIL_PLACEHOLDER || 'Entrer votre Email'}
          value={email()}
          onChange={handleEmailChange}
          icon={<FiMail size={16} class="my-auto" />}
          label={labels()?.EMAIL}
          required
        />
        <Input
          id="password"
          ref={refInputPassword}
          autocomplete='current-password'
          class="flex-auto"
          type="password"
          placeholder={labels()?.PASSWORD_PLACEHOLDER || 'Entrer votre password'}
          value={password()}
          onChange={handlePasswordChange}
          icon={<FiLock size={16} class="my-auto" />}
          label={labels()?.PASSWORD}
          required
        />
        <Button type="submit" disabled={signin.data.loading} text={labels()?.SIGN_IN_BUTTON || 'Sign in'}/>
      </form>
      <footer class="flex grow flex-col items-center justify-end  px-20 py-14 max-lg:px-8 max-lg:py-6">
        <a
          href={Paths.passwordReset}
          class="text-brand-500 grow text-sm leading-5"
        >
          {labels()?.FORGOTTEN_PASSWORD || 'Mot de passe oublié ?'}
        </a>
        <p class="ev-caption self-end text-[10px]">Copyright © Evoluno {new Date().getFullYear()}.</p>
      </footer>
    </main>
  );
};

export default LoginForm;
