import { Component } from 'solid-js';
import { Navigate, useLocation } from '@solidjs/router';
import { Paths } from '../../config';
import { useState } from '../../signals/context';

export const SaveLocationBeforeLogin: Component = () => {
  const {session} = useState();
  const location = useLocation();
  session().setRedirect(location.pathname === '/'
    ? Paths.home
    : location.pathname);
  return <Navigate href={Paths.login} />;
};
