import { gql } from 'graphql-request';
import { request } from '../graphql';

export const FEEDBACK_WITH_JSON = gql`
  mutation feedbackWithJson(
    $feedback: String!
  ) {
    feedbackWithJson(
      feedback: $feedback
    )
  }
`;

export type FeedbackWithJsonVars = {
  feedback: string
}

export const mutateFeedbackWithJson = (vars: FeedbackWithJsonVars) =>
  request<boolean>(FEEDBACK_WITH_JSON, vars);
