import * as menu from '@zag-js/menu';
import { A } from '@solidjs/router';
import { Component, createMemo, createUniqueId } from 'solid-js';
import { FiLogOut, FiUser } from 'solid-icons/fi';
import { Paths } from '../../config';
import { normalizeProps, useMachine } from '@zag-js/solid';
import { useState } from '../../signals/context';

export const MenuProfile: Component = () => {
  const {signout, user} = useState();
  const [state, send] = useMachine(menu.machine({
    id: createUniqueId(),
    'aria-label': 'Profile menu',
    positioning: {
      placement: 'bottom-end'
    },
    onSelect: details => {
      if (details.value === 'signout') {
        signout();
      }
    },
  }));
  const api = createMemo(() => menu.connect(state, send, normalizeProps));

  return (
    <div class="flex justify-end">
      <button {...api().triggerProps} class="bg-surface-accent rounded-full p-1">
        <FiUser size={24} class="stroke-content-accent hover:opacity-50" />
      </button>
      <div {...api().positionerProps} class="!z-50" >
        <ul {...api().contentProps}>
          <li
            class="border-border-secondary bg-surface-input w-80 rounded-xl border p-6 shadow-2xl"
          >
            <div class="flex items-start justify-between self-stretch px-4">
              <A class="flex cursor-pointer flex-col items-start" href={Paths.settings}>
                <div class="ev-subtitle2">{`${user.data().firstName} ${user.data().lastName}`}</div>
                <div class="ev-caption">{user.data().email}</div>
              </A>
              <button {...api().getItemProps({id: 'signout'})}>
                <FiLogOut size={16} class="stroke-content-accent hover:opacity-50" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
